*, *::after, *::before{
    padding: 0;
    margin: 0;
    box-sizing: content-box;
}

body{
    background-color: #fffbf6;
}


a{
    text-decoration: none;
    color: inherit;
}